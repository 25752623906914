import nanostyled from 'nanostyled';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Container = nanostyled('section', {
  base: 'w-full flex flex-col',
  bg: '',
});

export const Screen = nanostyled('div', {
  base: 'h-screen',
});

export const StyledImg = nanostyled(GatsbyImage, {
  base: 'w-full fixed top-0 bottom-0 left-0 right-0',
});

export const Background = nanostyled('section', {
  base: 'relative bg-white z-1',
});

export const InfoBox = nanostyled('div', {
  base:
    'flex flex-col md:flex-row justify-center max-w-7xl -mx-3px xl:mx-auto typography xl:mt-20',
});

export const InfoList = nanostyled('div', {
  base:
    'px-4 xs:px-10 pb-6 list-none border-solid border-2 -my-px md:my-0 md:-mx-px w-full',
  color: '',
});

export const ListItem = nanostyled('li', {
  base: 'font-micro font-medium mb-4 leading-tight',
  color: '',
});

export const PrevLink = nanostyled(Link, {
  base:
    'font-micro p-4 font-bold text-xxs uppercase fixed left-0 top-1/2 rotate-prev z-30 hidden sm:flex group items-center justify-center flex-col',
  color: '',
});

export const PrevTitle = nanostyled('div', {
  base: 'normal-case text-sm mt-2 hidden group-hover:block',
});

export const NextLink = nanostyled(Link, {
  base:
    'font-micro p-4 font-bold text-xxs uppercase fixed right-0 top-1/2 rotate-next z-30 hidden sm:flex group items-center justify-center flex-col',
  color: '',
});

export const NextTitle = nanostyled('div', {
  base: 'normal-case text-sm mb-2 hidden group-hover:block',
});
