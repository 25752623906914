import nanostyled from 'nanostyled';
import { Link } from 'gatsby';

export const Container = nanostyled('section', {
  base: 'w-screen flex flex-col items-center relative -mx-2px z-1 bg-white',
  bg: '',
});

export const StyledLink = nanostyled(Link, {
  base:
    'py-12 px-4 xs:px-10 md:px-16 lg:px-20 max-w-5xl w-full border-solid border-2 flex w-full -m-px sm:my-0 group',
  color: '',
});

export const Flex = nanostyled('div', {
  base: 'flex',
  justify: '',
  width: '',
  margin: '',
});

export const Title = nanostyled('div', {
  base: 'text-1p5xl font-normal mt-4 leading-tight group-hover:text-white',
});

export const Type = nanostyled('div', {
  base:
    'text-base xs:text-lg font-extrabold uppercase tracking-wide leading-snug',
  color: '',
});
